import { useEffect, useRef, useState } from 'react';
import ReactSelect, {
    components,
    OptionsOrGroups,
    GroupBase,
    MultiValue,
    MultiValueProps,
    createFilter,
} from 'react-select';
import { VariableSizeList as List } from 'react-window';

export type Option<T> = {
    value: T;
    label: string;
};

export type Props<T> = {
    value?: Option<T>[] | Option<T>;
    options: OptionsOrGroups<Option<T>, GroupBase<Option<T>>>;
    isMulti?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    autoFocus?: boolean;
    multiValueCounterMessage?: string;
    pluralMultiValueCounterMessage?: string;
    placeholder?: string;
    onChange: (value: Option<T> | MultiValue<Option<T>>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    className?: string;
    disabled?: boolean;
    id?: string;
};

// Must match the hieghts of .select-* styles
const OPTION_HEIGHT = 25;

const CheckboxOption = (props) => (
    <components.Option {...props}>
        {props.isSelected ? (
            <i className="fal fa-square-check multiselect-checkbox checked" />
        ) : (
            <i className="fal fa-square multiselect-checkbox" />
        )}
        {props.data.label}
    </components.Option>
);

// Mouse events skipped for performance
// Everything is handled on click or with CSS
const Option = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onMouseOver, onMouseMove, ...otherProps } = props.innerProps;
    return <components.Option {...{ ...props, innerProps: otherProps }} />;
};

const GroupHeading =
    <T,>(openList, toggle) =>
    // eslint-disable-next-line react/display-name
    (props) => {
        const open = openList.includes(props.data.label);
        const toggleOpen = () => toggle(props.data.label);

        if (props.selectProps.isMulti) {
            const count = props.data.options.filter((opt: Option<T>) =>
                props.selectProps.value.map((v: { value: T }) => v.value).includes(opt.value)
            ).length;

            let icon: string;

            if (count === 0) icon = 'fal fa-square';
            else if (props.data.options.length === count) icon = 'fal fa-square-check';
            else icon = 'fal fa-square-minus';

            const groupToggle = () => {
                const currentValues = props.selectProps.value;

                props.selectProps.onChange(
                    props.data.options.length === count
                        ? currentValues.filter(
                              (val: Option<T>) =>
                                  !props.data.options.map((opt: Option<T>) => opt.value).includes(val.value)
                          )
                        : currentValues.concat(
                              props.data.options.filter(
                                  (opt: Option<T>) =>
                                      !currentValues.map((val: Option<T>) => val.value).includes(opt.value)
                              )
                          )
                );
            };

            return (
                <components.GroupHeading {...props}>
                    <button
                        type="button"
                        className={`group-toggle ${count === 0 ? '' : 'checked'}`}
                        onClick={groupToggle}
                    >
                        <i className={icon} />
                    </button>
                    <button type="button" onClick={toggleOpen}>
                        {props.data.label}
                    </button>
                    <input id={`${props.id}-dropdown`} type="checkbox" checked={open} onChange={toggleOpen} />
                </components.GroupHeading>
            );
        }

        return (
            <components.GroupHeading {...props}>
                <button type="button" onClick={toggleOpen}>
                    <i className={open ? 'fas fa-chevron-down' : 'fas fa-chevron-up'} />
                    {props.data.label}
                </button>
                <input id={`${props.id}-dropdown`} type="checkbox" checked={open} onChange={toggleOpen} />
            </components.GroupHeading>
        );
    };

const MultiValueCounterBuilder = (multiValueCounterMessage: string, pluralMultiValueCounterMessage: string) => {
    const single = multiValueCounterMessage;
    const plural =
        multiValueCounterMessage === 'Selected'
            ? multiValueCounterMessage
            : pluralMultiValueCounterMessage ?? `${multiValueCounterMessage}s`;

    const MultiValueCounter = (props: MultiValueProps) => {
        if (props.index !== 0) return null;
        const { length } = props.getValue();
        if (props.selectProps.inputValue) return null;
        return <div className="option-label">{`${length === 1 ? single : plural} (${length})`}</div>;
    };
    return MultiValueCounter;
};

// Menu list uses react-window to only render the visibile options
// Some extra handling is needed to support searching and groups
const MenuList =
    (openList, scrollOffset, setScrollOffset) =>
    // eslint-disable-next-line react/display-name
    (props: { maxHeight: number; children; selectProps: { inputValue: string } }) => {
        const { maxHeight, children } = props;

        const listRef = useRef(null);

        const heights = Array.isArray(children)
            ? children.map((child) =>
                  openList.includes(child.props.data.label)
                      ? OPTION_HEIGHT * ((child.props.children?.length ?? 0) + 1)
                      : OPTION_HEIGHT
              )
            : [OPTION_HEIGHT];
        const heightSum = heights.length !== 0 ? heights.reduce((acc: number, value: number) => acc + value) : 0;
        const heightFunction = (i: number) => heights[i];

        // Trigger height recalculation when the search changes
        useEffect(() => {
            if (listRef.current) listRef.current.resetAfterIndex(0, true);
        }, [props.selectProps.inputValue]);

        useEffect(() => {
            if (listRef?.current && scrollOffset) {
                listRef.current.scrollTo(scrollOffset);
            }
        }, []);

        useEffect(() => {
            if ((listRef.current as List)?.state.scrollOffset !== 0)
                setScrollOffset((listRef.current as List)?.state.scrollOffset);
        }, [(listRef.current as List)?.state.scrollOffset]);

        return (
            <List
                ref={listRef}
                height={Math.min(Math.max(heightSum, OPTION_HEIGHT), maxHeight) + 3}
                itemCount={children.length || 1}
                itemSize={heightFunction}
            >
                {({ index, style }) => {
                    return <div style={style}>{Array.isArray(children) ? children[index] : children}</div>;
                }}
            </List>
        );
    };

const Select = <T,>({
    value,
    options,
    isMulti = false,
    isSearchable = false,
    isClearable = false,
    autoFocus = false,
    multiValueCounterMessage = 'Selected',
    pluralMultiValueCounterMessage,
    placeholder,
    onChange,
    onBlur,
    className,
    disabled,
    id,
}: Props<T>) => {
    const [openList, setOpenList] = useState<string[]>([]);
    const [scrollOffset, setScrollOffset] = useState<number>();

    return (
        <ReactSelect
            id={id}
            options={options}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            autoFocus={autoFocus}
            menuIsOpen={autoFocus ? true : undefined}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            className={`select ${className}`}
            hideSelectedOptions={false}
            components={{
                Option: isMulti ? CheckboxOption : Option,
                MultiValue: MultiValueCounterBuilder(multiValueCounterMessage, pluralMultiValueCounterMessage),
                GroupHeading: GroupHeading<T>(openList, (label) =>
                    setOpenList(openList.includes(label) ? openList.filter((l) => l !== label) : openList.concat(label))
                ),
                MenuList: MenuList(openList, scrollOffset, setScrollOffset),
            }}
            unstyled
            classNames={{
                control: () => 'select-control',
                valueContainer: () => 'select-value-container',
                dropdownIndicator: () => 'select-dropdown-indicator',
                input: () => 'select-input',
                menu: () => 'select-menu',
                menuList: () => 'select-menu-list',
                option: (state) => `select-option ${state.isSelected ? 'selected' : ''}`,
                groupHeading: () => 'select-group-heading',
                group: () => 'select-group',
                noOptionsMessage: () => 'select-no-options',
            }}
            value={value}
            isDisabled={disabled}
            menuPlacement="auto"
            filterOption={createFilter({ ignoreAccents: false })}
        />
    );
};

export default Select;
