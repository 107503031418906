import { Info } from './Tips';

interface Option {
    id: string;
    value: string;
    label: string;
    checked: boolean;
    disabled?: boolean;
    info?: string;
}

interface Props {
    name: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
    options: Option[];
    error?;
}

const Radio = (props: Props) => (
    <>
        <div className={`custom-radio ${props.error ? 'is-invalid' : ''}`}>
            {props.options.map((option) => (
                <div className="input-row" key={option.id}>
                    <label className="radio" htmlFor={option.id}>
                        <input
                            type="radio"
                            id={option.id}
                            value={option.value}
                            name={props.name}
                            checked={option.checked}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                        />
                        <span>{option.label}</span>
                    </label>
                    {option.info ? <Info id={`info-${option.id}`}>{option.info}</Info> : null}
                </div>
            ))}
        </div>
        <span className="invalid-feedback">{props.error}</span>
    </>
);

export default Radio;
