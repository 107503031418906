import { useEffect, useState } from 'react';
import { getService } from 'ServiceContainer';
import { CONTROLS_MODE } from 'services/Controls';
import { useAppDispatch, useAppSelector } from 'store';
import { fromVector3 } from 'types/common';
import * as giro3dSlice from 'redux/giro3d';
import { PopoverBody, Tooltip } from 'reactstrap';
import HeaderButton from 'components/flexLayout/HeaderButton';
import Button from 'components/dropdown/Button';

const getCameraIcon = (mode: CONTROLS_MODE) => {
    switch (mode) {
        case CONTROLS_MODE.PAN:
            return 'fas fa-arrows';
        case CONTROLS_MODE.DOLLY:
            return 'fas fa-arrows-v';
        case CONTROLS_MODE.ORBIT:
            return 'fas fa-solar-system';
        case CONTROLS_MODE.FOLLOW:
        case CONTROLS_MODE.RELEASED_FOLLOW:
            return 'fas fa-swap';
        case CONTROLS_MODE.ORTHOGRAPHIC:
            return 'fas fa-arrow-down-to-line';
        default:
            return 'fas fa-question';
    }
};

const CameraModeSetting = (props: { viewport: HTMLDivElement }) => {
    const { viewport } = props;
    const picker = getService('Picker');
    const dispatch = useAppDispatch();
    const mode = useAppSelector(giro3dSlice.getControlMode);
    const [isHelpDisplayed, setHelpDisplayed] = useState(false);

    const pickPivot = (e: MouseEvent) => {
        const point = picker.pickPoint(e);

        if (point) {
            dispatch(giro3dSlice.setControlsMode(CONTROLS_MODE.ORBIT));
            dispatch(giro3dSlice.setControlsTarget(fromVector3(point)));
            setHelpDisplayed(true);
        }
    };

    const setMode = (newMode: CONTROLS_MODE) => {
        let actualMode = newMode;
        if (newMode !== CONTROLS_MODE.FOLLOW) dispatch(giro3dSlice.resetFollowCamera());
        if (newMode === CONTROLS_MODE.ORBIT) actualMode = CONTROLS_MODE.DISABLED;
        else if (newMode === CONTROLS_MODE.FOLLOW) {
            dispatch(giro3dSlice.setFollowCameraModal(true));
            return;
        }

        dispatch(giro3dSlice.setControlsMode(actualMode));
    };

    const hideHelp = () => setHelpDisplayed(false);

    useEffect(() => {
        setHelpDisplayed(true);
        const timer = setTimeout(hideHelp, 5000);
        return () => clearTimeout(timer);
    }, [mode]);

    useEffect(() => setHelpDisplayed(false), []);

    useEffect(() => {
        if (mode === CONTROLS_MODE.DISABLED && viewport !== null) {
            viewport.addEventListener('mousedown', pickPivot);
            return () => {
                viewport.removeEventListener('mousedown', pickPivot);
            };
        }
        return null;
    }, [mode]);

    return (
        <>
            <HeaderButton
                id="giro3d-cameras"
                popover={{
                    name: 'Camera Mode',
                    icon: getCameraIcon(mode),
                    closeOnClick: true,
                    content: (
                        <PopoverBody>
                            <ul>
                                <Button
                                    title="Pan Camera"
                                    icon="fas fa-arrows"
                                    onClick={() => setMode(CONTROLS_MODE.PAN)}
                                />
                                <Button
                                    title="Dolly Camera"
                                    icon="fas fa-arrows-v"
                                    onClick={() => setMode(CONTROLS_MODE.DOLLY)}
                                />
                                <Button
                                    title="Orbit Camera"
                                    icon="fas fa-solar-system"
                                    onClick={() => setMode(CONTROLS_MODE.ORBIT)}
                                />
                                <Button
                                    title="Follow Camera"
                                    icon="fas fa-swap"
                                    onClick={() => setMode(CONTROLS_MODE.FOLLOW)}
                                />
                            </ul>
                        </PopoverBody>
                    ),
                }}
            />
            <Tooltip
                placement="bottom"
                fade={false}
                isOpen={mode === CONTROLS_MODE.FOLLOW && isHelpDisplayed}
                target="giro3d-cameras"
            >
                Use the follow menu to control the position
                <br />
                Left and right click: pan around
                <br />
                Scroll: zoom in/out
                <br />
                Space bar: play/pause
            </Tooltip>
            <Tooltip
                placement="bottom"
                fade={false}
                isOpen={mode === CONTROLS_MODE.DISABLED && isHelpDisplayed}
                target="giro3d-cameras"
            >
                Click on the map to set the orbit point
            </Tooltip>
            <Tooltip
                placement="bottom"
                fade={false}
                isOpen={mode === CONTROLS_MODE.ORBIT && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left and right click: orbit around
                <br />
                Scroll: zoom in/out from center
                <br />
                Key arrows: orbit around
            </Tooltip>
            <Tooltip
                placement="bottom"
                fade={false}
                isOpen={(mode === CONTROLS_MODE.PAN || mode === CONTROLS_MODE.RELEASED_FOLLOW) && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left click: pan
                <br />
                Right click: orbit around cursor
                <br />
                Scroll: zoom in/out from cursor
                <br />
                Key arrows: pan
                <br />
                Ctrl+Up/Ctrl+Down: move up/down
            </Tooltip>
            <Tooltip
                placement="bottom"
                fade={false}
                isOpen={mode === CONTROLS_MODE.DOLLY && isHelpDisplayed}
                target="giro3d-cameras"
                onClick={hideHelp}
                className="giro3d-controls-tooltip"
            >
                Left click: move forward/backwards
                <br />
                Right click: orbit around cursor
                <br />
                Scroll: zoom in/out from cursor
                <br />
                Key arrows: pan
                <br />
                Ctrl+Up/Ctrl+Down: move up/down
            </Tooltip>
        </>
    );
};

export default CameraModeSetting;
