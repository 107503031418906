import { getBaseName, SourceFile } from 'types/SourceFile';
import { SOURCE_FILE_STATES } from './Constants';

// Normalize the angles to the range [0, 360)
function normalize(deg: number) {
    return ((deg % 360) + 360) % 360;
}

function direction(start: number[], end: number[]) {
    return Math.atan2(start[0] - end[0], start[1] - end[1]) * (180 / Math.PI) + 180;
}

function isAngleInTarget(angle: number, targetAngle: number, targetWidth: number, checkOpposed = false) {
    // Normalize the input angle and target angle
    const normalizedAngle = normalize(angle);
    const normalizedTarget = normalize(targetAngle);

    // Calculate half width of the target
    const halfWidth = targetWidth / 2;

    // Determine the lower and upper bounds of the target area
    const lowerBound = normalize(normalizedTarget - halfWidth);
    const upperBound = normalize(normalizedTarget + halfWidth);

    // Function to check if an angle is within the target bounds
    const isInBounds = (testAngle) => {
        if (lowerBound < upperBound) return testAngle >= lowerBound && testAngle <= upperBound;
        // Case where the target area wraps around 0 degrees
        return testAngle >= lowerBound || testAngle <= upperBound;
    };

    // Check if the angle is within the target area
    if (isInBounds(normalizedAngle)) return true;

    // If checking the opposed area, shift the angle by 180 degrees
    if (checkOpposed) return isInBounds(normalize(normalizedAngle + 180));

    return false;
}

function isInline(angle: number, error: number): boolean {
    return isAngleInTarget(angle, 0, error, true);
}

function findRelativeOrder(a: number[], b: number[], axis: number): number {
    return isAngleInTarget(direction(a, b) - axis + 90, 0, 180) ? 1 : -1;
}

function center(sourcefile: SourceFile): number[] {
    const length = sourcefile.geometry?.coordinates.length;
    if (!length) return [0, 0];
    return [
        (sourcefile.geometry.coordinates[0][0] + sourcefile.geometry.coordinates[length - 1][0]) / 2,
        (sourcefile.geometry.coordinates[0][1] + sourcefile.geometry.coordinates[length - 1][1]) / 2,
    ];
}

export function isBackwards(
    sourcefile: SourceFile,
    inlineDirection: number,
    crosslineDirection: number,
    inlineTolerance: number,
    crosslineTolerance: number
): boolean {
    const dir = direction(
        sourcefile.geometry.coordinates[0] as number[],
        sourcefile.geometry.coordinates[sourcefile.geometry.coordinates.length - 1] as number[]
    );
    return (
        isAngleInTarget(dir, inlineDirection + 180, inlineTolerance, false) ||
        isAngleInTarget(dir, crosslineDirection + 180, crosslineTolerance, false)
    );
}

export function organizeLines(
    sourcefiles: SourceFile[],
    inlineDirection: number,
    crosslineDirection: number,
    inlineTolerance: number,
    crosslineTolerance: number
): { inline: SourceFile[]; crossline: SourceFile[]; other: SourceFile[] } {
    if (!sourcefiles) return { inline: [], crossline: [], other: [] };

    sourcefiles = sourcefiles.filter((s) => s.state !== SOURCE_FILE_STATES.UPLOADING);

    const inline = sourcefiles
        .filter((s) =>
            s.geometry
                ? isInline(
                      direction(
                          s.geometry.coordinates[0] as number[],
                          s.geometry.coordinates[s.geometry.coordinates.length - 1] as number[]
                      ) - inlineDirection,
                      inlineTolerance
                  )
                : false
        )
        .sort((a, b) => findRelativeOrder(center(a), center(b), inlineDirection));
    const crossline = sourcefiles
        .filter((s) =>
            !inline.includes(s) && s.geometry
                ? isInline(
                      direction(
                          s.geometry.coordinates[0] as number[],
                          s.geometry.coordinates[s.geometry.coordinates.length - 1] as number[]
                      ) - crosslineDirection,
                      crosslineTolerance
                  )
                : false
        )
        .sort((a, b) => findRelativeOrder(center(a), center(b), crosslineDirection));
    const other = sourcefiles.filter((s) => !inline.includes(s) && !crossline.includes(s));

    return { inline, crossline, other };
}

function averageAngleWithFlip(angles: number[]): number {
    // Step 1: Calculate the raw average direction
    let x = 0;
    let y = 0;

    angles.forEach((angle) => {
        const radians = normalize(angle) * (Math.PI / 180); // Convert to radians
        x += Math.cos(radians);
        y += Math.sin(radians);
    });

    let avgX = x / angles.length;
    let avgY = y / angles.length;
    let avgAngle = Math.atan2(avgY, avgX) * (180 / Math.PI); // Initial average
    avgAngle = normalize(avgAngle); // Normalize the average to [0, 360)

    // Step 2: Flip angles that are more than 90 degrees away from the current average
    x = 0;
    y = 0;

    angles.forEach((angle) => {
        let adjustedAngle = normalize(angle);
        if (Math.abs(normalize(adjustedAngle - avgAngle)) > 90) {
            // Flip the angle by 180 degrees
            adjustedAngle = normalize(adjustedAngle + 180);
        }

        const radians = adjustedAngle * (Math.PI / 180); // Convert to radians
        x += Math.cos(radians);
        y += Math.sin(radians);
    });

    // Step 3: Calculate the final average with flipped angles
    avgX = x / angles.length;
    avgY = y / angles.length;
    avgAngle = Math.atan2(avgY, avgX) * (180 / Math.PI);

    // Normalize the final result to [0, 360)
    return normalize(avgAngle);
}

export function groupName(
    sourcefiles: SourceFile[],
    inlineTerm: string,
    crosslineTerm: string
): { inline: SourceFile[]; crossline: SourceFile[]; other: SourceFile[] } {
    if (!sourcefiles) return { inline: [], crossline: [], other: [] };

    const inline = sourcefiles.filter((s) => getBaseName(s).includes(inlineTerm));
    const crossline = sourcefiles.filter((s) => !inline.includes(s) && getBaseName(s).includes(crosslineTerm));
    const other = sourcefiles.filter((s) => !inline.includes(s) && !crossline.includes(s));

    const inlineDirection = averageAngleWithFlip(
        inline.map((s) =>
            s.geometry
                ? direction(
                      s.geometry.coordinates[0] as number[],
                      s.geometry.coordinates[s.geometry.coordinates.length - 1] as number[]
                  )
                : 0
        )
    );
    const crosslineDirection = averageAngleWithFlip(
        crossline.map((s) =>
            s.geometry
                ? direction(
                      s.geometry.coordinates[0] as number[],
                      s.geometry.coordinates[s.geometry.coordinates.length - 1] as number[]
                  )
                : 0
        )
    );

    return {
        inline: inline.sort((a, b) => findRelativeOrder(center(a), center(b), inlineDirection)),
        crossline: crossline.sort((a, b) => findRelativeOrder(center(a), center(b), crosslineDirection)),
        other,
    };
}
