import { useDispatch } from 'react-redux';
import { startMeasureDrawing, clearMeasureDrawing } from 'redux/actions';
import { useAppSelector } from 'store';
import { DRAWN_TOOL } from 'services/Constants';
import { useEffect } from 'react';
import GeometryInfo from '../annotationMenu/GeometryInfo';
import * as drawToolSlice from '../../../redux/drawTool';
import DisabledDrawTool from '../DisabledDrawTool';

const MeasureMenu = () => {
    const dispatch = useDispatch();
    const currentTool = useAppSelector(drawToolSlice.getTool);
    const geometry = useAppSelector(drawToolSlice.getActiveGeometry);

    useEffect(() => {
        return () => {
            dispatch(clearMeasureDrawing());
        };
    }, []);

    return !currentTool || currentTool === DRAWN_TOOL.MEASURE ? (
        <div className="tabContent">
            <GeometryInfo
                geometry={geometry}
                controls="Click to mark points. Right click to end the line. Right click the first point to make a polygon."
            />
            <hr />
            <div className="input-row">
                <button
                    type="button"
                    className="pane-button"
                    id="cancel-drawing"
                    onClick={() => dispatch(clearMeasureDrawing())}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="pane-button highlight"
                    id="start-drawing"
                    onClick={() => dispatch(startMeasureDrawing())}
                >
                    Measure
                </button>
            </div>
        </div>
    ) : (
        <div className="tabContent">
            <DisabledDrawTool />
        </div>
    );
};

export default MeasureMenu;
