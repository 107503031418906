import { useAppDispatch, useAppSelector } from 'store';
import * as giro3dSlice from 'redux/giro3d';
import { CONTROLS_MODE } from 'services/Controls';

const PerspectiveCameraSetting = () => {
    const dispatch = useAppDispatch();
    const isOrthographic = useAppSelector(giro3dSlice.getControlMode) === CONTROLS_MODE.ORTHOGRAPHIC;

    const toggle = () => {
        dispatch(giro3dSlice.setControlsMode(isOrthographic ? CONTROLS_MODE.PAN : CONTROLS_MODE.ORTHOGRAPHIC));
    };

    return (
        <div className="tabHeaderInput">
            <div>
                <button
                    type="button"
                    className="toggled"
                    id="3d-mode"
                    onClick={toggle}
                    title="3D Mode"
                    disabled={!isOrthographic}
                >
                    3D
                </button>
            </div>
            <div>
                <button
                    type="button"
                    className="toggled"
                    id="3d-mode"
                    onClick={toggle}
                    title="2D Mode"
                    disabled={isOrthographic}
                >
                    2D
                </button>
            </div>
        </div>
    );
};

export default PerspectiveCameraSetting;
