import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

interface Props {
    id: string;
}

export const Info = (props: React.PropsWithChildren<Props>) => (
    <>
        <div id={props.id} className="tip info fas fa-square-info" />
        <UncontrolledTooltip target={props.id} placement="bottom" hideArrow>
            <span className="submenu-tooltip">{props.children}</span>
        </UncontrolledTooltip>
    </>
);

export const Warning = (props: React.PropsWithChildren<Props>) => (
    <>
        <div id={props.id} className="tip warning fas fa-triangle-exclamation" />
        <UncontrolledTooltip target={props.id} placement="bottom" hideArrow>
            <span className="submenu-tooltip">{props.children}</span>
        </UncontrolledTooltip>
    </>
);
