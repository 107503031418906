import { Form, Formik } from 'formik';
import { shallowEqual } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Annotation from 'types/Annotation';
import store, { useAppDispatch, useAppSelector } from 'store';
import { DatasetId, SsdmType } from 'types/common';
import Dataset from 'types/Dataset';
import {
    editAnnotation,
    applyAnnotationEdit,
    relateAnnotationDatasets,
    unrelateAnnotationDatasets,
    setAnnotationDatasets,
    cancelAnnotationEdit,
    stopAnnotationEdit,
} from 'redux/actions';
import { getSSDMTypes } from 'redux/selectors';

import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';

import { EDITSTATE, SSDM_CLASSIFICATION_TYPES } from 'services/Constants';

import Radio from 'components/Radio';
import Select from '../../forms/Select';
import GeometryInfo from './GeometryInfo';
import BaseField from '../../forms/BaseField';

export function getDataset(id: DatasetId): Dataset {
    return datasetsSlice.get(id)(store.getState());
}

export function isVisible(id: DatasetId): boolean {
    const state = store.getState();
    const layerState = layersSlice.get(id)(state);
    if (!layerState) {
        return false;
    }

    return layerState.visible;
}

export type Props = {
    annotation: Annotation;
    onPointDeleted: (pointIndex: number) => void;
    onPointUpdated: (pointIndex: number, x: number, y: number, z: number) => void;
};

const EditAnnotation = (props: Props) => {
    const dispatch = useAppDispatch();

    const editState = useAppSelector(annotationsSlice.editState);
    const SSDMTypes = useAppSelector<SsdmType[]>(getSSDMTypes);
    const activeAnnotation = props.annotation;
    const datasets = useAppSelector<Dataset[]>(datasetsSlice.getRenderableDatasets, shallowEqual);

    if (!activeAnnotation) return null;

    const classificationOptions = [];

    Object.keys(SSDM_CLASSIFICATION_TYPES).forEach((type) => {
        classificationOptions.push({ label: SSDM_CLASSIFICATION_TYPES[type], options: [] });
    });

    const geometryType = activeAnnotation.geometry.type;

    SSDMTypes.forEach((SSDM: SsdmType) => {
        if (
            (geometryType === 'Polygon' && SSDM.geom_type === 'POLYGON') ||
            (geometryType === 'LineString' && SSDM.geom_type === 'POLYLINE') ||
            (geometryType === 'Point' && SSDM.geom_type === 'POINT')
        ) {
            classificationOptions[Object.keys(SSDM_CLASSIFICATION_TYPES).indexOf(SSDM.parent_type)].options.push({
                label: `${SSDM.name} ${SSDM.display_name}`,
                value: SSDM.name,
            });
        }
    });

    const datasetOptions = datasets.map((dataset) => ({ label: dataset.name, value: dataset.id }));

    const endEdit = () => {
        dispatch(stopAnnotationEdit());
    };
    const abortEdit = () => {
        dispatch(cancelAnnotationEdit(activeAnnotation));
    };
    const applyEdit = () => {
        dispatch(applyAnnotationEdit(activeAnnotation));
    };

    switch (editState) {
        case EDITSTATE.DRAWING:
            return (
                <div className="tabContent">
                    <div className="inspector-title">
                        <span>
                            {activeAnnotation.name} ({activeAnnotation.geometry.type})
                        </span>
                    </div>
                    <hr />
                    <GeometryInfo
                        geometry={activeAnnotation.geometry}
                        controls={
                            <>
                                <b>Left-click + drag</b> on a point to move it. <b>Left-click</b> on a segment to insert
                                a point. <b>Middle-click</b> or <b>Alt + Left-click</b> to remove points. Press{' '}
                                <b>Apply</b> to finish.
                            </>
                        }
                        onPointDeleted={props.onPointDeleted}
                        onPointUpdated={props.onPointUpdated}
                    />
                    <hr />
                    <div className="input-row">
                        <button type="button" className="pane-button" id="cancel-drawing" onClick={abortEdit}>
                            Cancel
                        </button>
                        <button type="button" className="pane-button highlight" id="apply-drawing" onClick={applyEdit}>
                            Apply
                        </button>
                    </div>
                </div>
            );
        case EDITSTATE.DETAILS:
        case EDITSTATE.SUMBIT:
        case EDITSTATE.COMPLETE:
            return (
                <div className="tabContent">
                    <Formik
                        initialValues={{
                            name: activeAnnotation.name,
                            description: activeAnnotation.description,
                            type: activeAnnotation.ssdm_type === null ? 'observation' : 'object',
                            classificationType: {
                                label: `${activeAnnotation.ssdm_type} ${SSDMTypes.find((SSDM) => SSDM.name === activeAnnotation.ssdm_type)?.display_name}`,
                                value: activeAnnotation.ssdm_type,
                            },
                            datasets: activeAnnotation.datasets.map((id) => ({
                                label: getDataset(id).name,
                                value: id,
                                faded: !isVisible(id),
                            })),
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={(values) => {
                            const errors: {
                                name?: string;
                                type?: string;
                                classificationType?: string;
                            } = {};
                            if (!values.name) errors.name = 'Required';
                            if (!values.type) errors.type = 'Required';
                            if (values.type === 'object' && !values.classificationType)
                                errors.classificationType = 'Required';
                            return errors;
                        }}
                        onSubmit={(values) => {
                            const annotation: Partial<Annotation> = {
                                name: values.name,
                                description: values.description,
                                project_id: activeAnnotation.project_id,
                                id: activeAnnotation.id,
                                ssdm_type: values.type !== 'observation' ? values.classificationType.value : null,
                            };

                            const datasetIds = values.datasets.map((dataset) => dataset.value);
                            const idsToRemove = activeAnnotation.datasets.filter((id) => !datasetIds.includes(id));
                            const idsToAdd = datasetIds.filter((id) => !activeAnnotation.datasets.includes(id));

                            dispatch(editAnnotation(annotation))
                                .then(() => {
                                    unrelateAnnotationDatasets(activeAnnotation, idsToRemove);
                                    relateAnnotationDatasets(activeAnnotation, idsToAdd);
                                    dispatch(setAnnotationDatasets(activeAnnotation.id, datasetIds));
                                })
                                .then(endEdit);
                        }}
                    >
                        {({ isSubmitting, values, handleChange, handleBlur, errors, setFieldValue }) => (
                            <Form className="sideform">
                                <BaseField
                                    name="name"
                                    placeholder="Title of annotation"
                                    type="text"
                                    required="required"
                                />
                                <hr />
                                <Radio
                                    name="type"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    options={[
                                        {
                                            id: 'observation',
                                            value: 'observation',
                                            label: 'Observation',
                                            checked: values.type === 'observation',
                                            info: 'An observation can be used to label anything: location, interesting data, data issues, etc.',
                                        },
                                        {
                                            id: 'object',
                                            value: 'object',
                                            label: 'Object',
                                            checked: values.type === 'object',
                                            info: 'An object is something that can be classified by the SSDM standard.',
                                        },
                                    ]}
                                />
                                {errors.type ? <span className="invalid-feedback">{errors.type}</span> : null}
                                {values.type === 'object' ? (
                                    <>
                                        <hr />
                                        <Select
                                            name="classificationType"
                                            value={values.classificationType}
                                            options={classificationOptions}
                                            placeholder="Classification"
                                            isSearchable
                                            setFieldValue={setFieldValue}
                                        />
                                    </>
                                ) : null}
                                <hr />
                                <Select
                                    name="datasets"
                                    isMulti
                                    options={datasetOptions}
                                    placeholder="Connect datasets"
                                    multiValueCounterMessage="Connected dataset"
                                    value={activeAnnotation.datasets.map((id) => ({
                                        label: getDataset(id).name,
                                        value: id,
                                        faded: !isVisible(id),
                                    }))}
                                    setFieldValue={setFieldValue}
                                />
                                <hr />
                                <BaseField
                                    name="description"
                                    label="Description"
                                    type="text"
                                    as="textarea"
                                    value={values.description}
                                />
                                <hr />
                                <div className="input-row">
                                    <button
                                        type="button"
                                        className="pane-button"
                                        id="cancel-creation"
                                        onClick={abortEdit}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="pane-button highlight"
                                        id="complete-creation"
                                        disabled={
                                            isSubmitting ||
                                            editState === EDITSTATE.SUMBIT ||
                                            editState === EDITSTATE.COMPLETE
                                        }
                                    >
                                        Save
                                    </button>
                                </div>
                                <Modal centered className="modal-confirm" isOpen={editState === EDITSTATE.COMPLETE}>
                                    <ModalHeader />
                                    <ModalBody>
                                        <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                                        <span className="big-modal-text">Annotation created</span>
                                    </ModalBody>
                                    <ModalFooter />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                </div>
            );
        default:
            return <span>The state is {editState}</span>;
    }
};

export default EditAnnotation;
