// React
import { MutableRefObject } from 'react';

const Giro3dControls = (props: { compassRef: MutableRefObject<null>; hidden: boolean; disabled: boolean }) => (
    <div className="map-controls map-controls-row no-gap" hidden={props.hidden}>
        <div>
            <div ref={props.compassRef} className={props.disabled ? 'disabled' : ''} />
        </div>
    </div>
);

export default Giro3dControls;
