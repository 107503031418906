import { AnnotationGeometry } from 'types/Annotation';
import type { Position } from 'geojson';
import { Button } from 'reactstrap';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import BaseInput from 'components/forms/BaseInput';
import { Info } from 'components/Tips';
import { getArea } from '../../../giro3d_extensions/Measure';

export type Props = {
    /**
     * The geometry to display.
     */
    geometry: AnnotationGeometry;
    /**
     * Optional description of the current controls.
     */
    controls?: React.ReactNode;
    /**
     * Optional callback to delete a point. If undefined, points cannot be deleted.
     */
    onPointDeleted?: (pointIndex: number) => void;
    /**
     * Optional callback to update a point's coordinates. If undefined, points cannot be moved.
     */
    onPointUpdated?: (pointIndex: number, x: number, y: number, z: number) => void;
};

function formatLength(value: number): string {
    if (Math.ceil(Math.log10(value)) > 4) return `${(value / 1000).toFixed(2)}km`;
    return `${value.toFixed(2)}m`;
}

function formatArea(value: number): string {
    if (Math.ceil(Math.log10(value)) > 7) return `${(value / 1000000).toFixed(0)}km²`;
    return `${value.toFixed(0)}m²`;
}

const Vertex = (props: {
    index: number;
    coordinate: Position;
    deletePoint: () => void;
    updatePoint: (x, y, z) => void;
}) => {
    const [editing, setEditing] = useState(false);

    return (
        <div className="vertex">
            <div>{props.index}</div>
            {editing ? (
                <Formik
                    onSubmit={(values) => {
                        props.updatePoint(values.x, values.y, values.z);
                        setEditing(false);
                    }}
                    initialValues={{
                        x: props.coordinate[0],
                        y: props.coordinate[1],
                        z: props.coordinate[2],
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form autoComplete="off">
                            <div>
                                <span className="coordinate-component x">X: </span>
                                <BaseInput type="number" name="x" />
                            </div>
                            <div>
                                <span className="coordinate-component y">Y: </span>
                                <BaseInput type="number" name="y" />
                            </div>
                            <div>
                                <span className="coordinate-component z">Z: </span>
                                <BaseInput type="number" name="z" />
                            </div>
                            <div className="input-row">
                                <button
                                    type="button"
                                    onClick={() => setEditing(false)}
                                    className="pane-button"
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="pane-button highlight" disabled={isSubmitting}>
                                    Update
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <div>
                        <div>
                            <span className="coordinate-component x">X: </span>
                            {props.coordinate[0].toFixed(2)}
                        </div>
                        <div>
                            <span className="coordinate-component y">Y: </span>
                            {props.coordinate[1].toFixed(2)}
                        </div>
                        <div>
                            <span className="coordinate-component z">Z: </span>
                            {props.coordinate[2].toFixed(2)}
                        </div>
                    </div>
                    <div>
                        {props.deletePoint ? (
                            <Button title="Delete Point" onClick={props.deletePoint} className="borderless red">
                                <i className="fas fa-trash-can" />
                            </Button>
                        ) : null}
                        {props.updatePoint ? (
                            <Button title="Edit Point" onClick={() => setEditing(true)} className="borderless green">
                                <i className="fas fa-pen" />
                            </Button>
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};

const Edge = (props: {
    horizontalDistance: number;
    verticalDistance: number;
    lineDistance: number;
    direction: number;
}) => {
    return (
        <div className="edge">
            <div title="Distance">
                <span>ΔXYZ</span>
                {formatLength(props.lineDistance)}
            </div>
            <div title="Horizontal Distance">
                <span>ΔXY</span>
                {formatLength(props.horizontalDistance)}
            </div>
            <div title="Vertical Distance">
                <span>ΔZ</span>
                {formatLength(props.verticalDistance)}
            </div>
            <div title="Direction">
                <i className="fas fa-compass" />
                {props.direction.toFixed(1)}°
            </div>
        </div>
    );
};

const AggregateValues = (props: {
    intermediates: {
        horizontalDistance: number;
        verticalDistance: number;
        lineDistance: number;
        direction: number;
    }[];
    geometry: AnnotationGeometry;
}) => {
    if (!props.geometry) return null;
    return (
        <table className="aggregate">
            <tbody>
                {props.geometry.type === 'Polygon' ? (
                    <tr title="Area">
                        <td>Area</td>
                        <td>{formatArea(getArea(props.geometry))}</td>
                    </tr>
                ) : null}
                {props.geometry.type !== 'Point' ? (
                    <>
                        <tr title={props.geometry.type === 'Polygon' ? '3D Perimeter (XYZ)' : '3D Length (XYZ)'}>
                            <td>{props.geometry.type === 'Polygon' ? '3D Perimeter (XYZ)' : '3D Length (XYZ)'}</td>
                            <td>{formatLength(props.intermediates.reduce((acc, val) => acc + val.lineDistance, 0))}</td>
                        </tr>
                        <tr title={props.geometry.type === 'Polygon' ? '2D Perimeter (XY)' : '2D Length (XY)'}>
                            <td>{props.geometry.type === 'Polygon' ? '2D Perimeter (XY)' : '2D Length (XY)'}</td>
                            <td>
                                {formatLength(
                                    props.intermediates.reduce((acc, val) => acc + val.horizontalDistance, 0)
                                )}
                            </td>
                        </tr>
                    </>
                ) : null}
            </tbody>
        </table>
    );
};

function getCoordinates(geometry: AnnotationGeometry): Position[] {
    switch (geometry?.type) {
        case 'Point':
            return [geometry.coordinates];
        case 'LineString':
            return geometry.coordinates;
        case 'Polygon':
            return geometry.coordinates[0];
        default:
            return [];
    }
}

function getIntermediates(coordinates: Position[]): {
    horizontalDistance: number;
    verticalDistance: number;
    lineDistance: number;
    direction: number;
}[] {
    return Array.from({ length: coordinates.length - 1 }).map((_, i) => {
        const dx = coordinates[i][0] - coordinates[i + 1][0];
        const dy = coordinates[i][1] - coordinates[i + 1][1];
        const dz = coordinates[i][2] - coordinates[i + 1][2];

        return {
            horizontalDistance: Math.sqrt(dx * dx + dy * dy),
            verticalDistance: dz,
            lineDistance: Math.sqrt(dx * dx + dy * dy + dz * dz),
            direction: (Math.atan2(dx, dy) * (180 / Math.PI) + 360) % 360,
        };
    });
}

function canDelete(index: number, type: string, points: number): boolean {
    switch (type) {
        case 'Point':
            return false;
        case 'LineString':
            return points > 2;
        case 'Polygon':
            return points > 3;
        default:
            return false;
    }
}

const GeometryInfo = (props: Props) => {
    const { geometry, onPointDeleted: onDeletePoint, onPointUpdated: onUpdatePoint } = props;

    const coordinates = getCoordinates(geometry);
    const intermediates = getIntermediates(coordinates);

    return (
        <div className="geometry-readout">
            {props.controls ? (
                <div>
                    <Info id="controls">{props.controls}</Info> Controls
                </div>
            ) : null}
            {coordinates.map((coordinate: Position, i: number) => (
                <>
                    <Vertex
                        index={i}
                        coordinate={coordinate}
                        deletePoint={
                            onDeletePoint && canDelete(i, geometry.type, coordinates.length)
                                ? () => onDeletePoint(i)
                                : undefined
                        }
                        updatePoint={onUpdatePoint ? (x, y, z) => onUpdatePoint(i, x, y, z) : undefined}
                    />
                    {i !== coordinates.length - 1 && coordinates.length > 1 ? <Edge {...intermediates[i]} /> : null}
                </>
            ))}
            <AggregateValues intermediates={intermediates} geometry={geometry} />
        </div>
    );
};

export default GeometryInfo;
